
import { defineComponent } from 'vue';

import Hero from '@/components/Hero.vue';
import Quote from '@/components/Quote.vue';

export default defineComponent({
  components: { Hero, Quote },

  data() {
    return {
      // TODO: optimize cropping
      hero: 'home-1203963917.webp',
    };
  },
});
