
import { defineComponent } from 'vue';

import Hero from '@/components/Hero.vue';

export default defineComponent({
  components: { Hero },
  data() {
    return {
      // TODO: optimize cropping
      hero: 'voor-wie-867341954.webp',
    };
  },
});
