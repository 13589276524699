
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Hero',
  props: {
    image: String,
    title: String,
  },

  data() {
    return {
      imageUrl: `/assets/img/heroes/${this.image}`,
    };
  },

  // mounted() {
  //   // const container = document.getElementsByClassName('heroo');
  //   const windowHeight = window.innerHeight;
  //   // const windowWidth = window.innerWidth;
  //   const scrollArea = 1600 - windowHeight;
  //   const hero = document.getElementsByClassName('hero-image')[0] as HTMLElement;
  //
  //   window.addEventListener('scroll', () => {
  //     const scrollTop = window.pageYOffset || window.screenTop;
  //     const scrollPercent = scrollTop / scrollArea || 0;
  //
  //     hero.style.top = `${50 - scrollPercent * window.innerWidth * -0.03}%`;
  //   });
  // },
});
