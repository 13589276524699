
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      currentYear: (new Date()).getFullYear(),
    };
  },
});
