<template>
  <nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-primary">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img src="@/assets/img/logo_glyph.svg" alt="" width="48" height="48">
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
              aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a href="/" class="nav-link" :class="{active: $route.name==='Home'}">
              Home
            </a>
          </li>
          <li class="nav-item">
            <a href="/voor" class="nav-link" :class="{active: $route.name==='Voor Wie'}">
              Voor Wie
            </a>
          </li>
          <li class="nav-item">
            <a href="/werkwijze" class="nav-link" :class="{active: $route.name==='Werkwijze'}">
              Werkwijze
            </a>
          </li>
          <li class="nav-item">
            <a href="/resultaat" class="nav-link" :class="{active: $route.name==='Resultaat'}">
              Resultaat
            </a>
          </li>
          <li class="nav-item">
            <a href="/tarieven" class="nav-link"
                         :class="{active: $route.name==='Tarieven & Vergoedingen'}">
              Tarieven & Vergoeding
            </a>
          </li>
          <li class="nav-item">
            <a href="/over" class="nav-link" :class="{active: $route.name==='Over Mij'}">
              Over Mij
            </a>
          </li>
          <li class="nav-item">
            <a href="/contact" class="nav-link" :class="{active: $route.name==='Contact'}">
              Contact
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
