import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '@/views/Home.vue';
import Voor from '@/views/Voor.vue';
import Werkwijze from '@/views/Werkwijze.vue';
import Resultaat from '@/views/Resultaat.vue';
import Tarieven from '@/views/Tarieven.vue';
import Over from '@/views/Over.vue';
import Contact from '@/views/Contact.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/voor',
    name: 'Voor Wie',
    component: Voor,
  },
  {
    path: '/werkwijze',
    name: 'Werkwijze',
    component: Werkwijze,
  },
  {
    path: '/resultaat',
    name: 'Resultaat',
    component: Resultaat,
  },
  {
    path: '/tarieven',
    name: 'Tarieven & Vergoedingen',
    component: Tarieven,
  },
  {
    path: '/over',
    name: 'Over Mij',
    component: Over,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    if (savedPosition) {
      return savedPosition;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    return false;
  },
});

export default router;
