<template>
  <div class="container">
    <div class="row">
      <div class="container">
        <blockquote class="quote-card">
          <slot></slot>
          <cite v-if="cite">{{ cite }}</cite>
        </blockquote>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    cite: String,
    quote: String,
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
body {
  background: #eee;
}

.container {
  max-width: 800px;
  margin:10px auto;
}

.text-center {
  text-align: center;
}

.quote-card {
  /*background: #fff;*/
  /*color: #222222;*/
  color: #8c7a6b;
  padding: 20px;
  padding-left: 50px;
  box-sizing: border-box;
  /*box-shadow: 0 2px 4px rgba(34, 34, 34, 0.48);*/
  position: relative;
  overflow: hidden;
  min-height: 120px;
  /*border-radius: 10px;*/
}
.quote-card p {
  font-family: "Courgette";
  font-size: 28px;
  text-align: center;
  line-height: 1.5;
  margin: 0;
  max-width: 80%;
}
.quote-card cite {
  font-size: 16px;
  margin-top: 10px;
  display: block;
  font-weight: 200;
  opacity: 0.8;
}
.quote-card:before {
  font-family: Georgia, serif;
  content: "“";
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 5em;
  /*color: rgba(238, 238, 238, 0.8);*/
  font-weight: normal;
}
.quote-card:after {
  font-family: Georgia, serif;
  content: "”";
  position: absolute;
  bottom: -100px;
  line-height: 100px;
  right: 32px;
  font-size: 25em;
  /*color: rgba(238, 238, 238, 0.8);*/
  font-weight: normal;
}
@media (max-width: 640px) {
  .quote-card:after {
    font-size: 22em;
    right: -25px;
  }
}
</style>
