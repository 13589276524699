import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cb8b8b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hero-section" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("img", {
      src: _ctx.imageUrl,
      alt: "hero",
      class: "hero-image"
    }, null, 8, _hoisted_3)
  ]))
}