
import { defineComponent } from 'vue';

import Hero from '@/components/Hero.vue';

export default defineComponent({
  components: { Hero },
  data() {
    return {
      hero: 'tarieven-466249702.webp',
    };
  },
});
