
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { defineComponent } from 'vue';

import Hero from '@/components/Hero.vue';

export default defineComponent({
  components: { Hero },
  data() {
    return {
      hero: 'contact-845547386.webp',
      token: process.env.VUE_APP_MAPBOX_API_TOKEN,
      location: [6.0476194, 51.4485644] as [number, number],
    };
  },

  mounted() {
    this.createMap();
  },

  methods: {
    async createMap() {
      try {
        mapboxgl.accessToken = this.token;
        const map = new mapboxgl.Map({
          center: this.location,
          container: 'mapContainer',
          style: 'mapbox://styles/mapbox/streets-v11',
          zoom: 14,
        });

        new mapboxgl.Marker({
          // TODO: check if this could be made dynamic
          color: '#d5a021', // $logo-gold
        })
          .setLngLat(this.location)
          .addTo(map);
      } catch (err) {
        console.log('map error', err);
      }
    },
  },
});
